import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';
import { Observable, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export const PublicGuard = ():
  | Observable<boolean | Promise<boolean>>
  | boolean => {
  const user = inject(UserService);
  const translate = inject(TranslateService);
  const lang = translate.currentLang;
  const router = inject(Router);

  return user.isLogged.pipe(
    map(async (isLogged) => {
      if (isLogged) {
        return router.navigate([lang, 'kids']);
      }
      return true;
    })
  );
};
