import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { UtilitiesService } from '../services/utilities/utilities.service';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContactMeComponent } from '../contact/contact-me/contact-me.component';
import { BookmeComponent } from '../landing-page/bookme/bookme.component';
import { MarketingService } from '../services/marketing/marketing.service';
import { ContactUsModalComponent } from '../contact-us-modal/contact-us-modal.component';
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';
import { UserService } from '../services/user/user.service';
import { AsyncPipe } from '@angular/common';
import { MatNavList, MatListItem } from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import {
  MatButton,
  MatMiniFabButton,
  MatIconButton,
} from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatToolbar } from '@angular/material/toolbar';
import { FlagsIconComponent } from '../flags-icon/flags-icon.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('in => out', animate('200ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out')),
    ]),
  ],
  standalone: true,
  imports: [
    MatToolbar,
    FlexModule,
    RouterLink,
    MatButton,
    MatIcon,
    MatMiniFabButton,
    RouterLinkActive,
    MatIconButton,
    MatNavList,
    MatListItem,
    TranslateModule,
    AsyncPipe,
    FlagsIconComponent,
  ],
})
export class NavbarComponent implements OnChanges, OnInit {
  @Input('closeNavbar') closeNavbar;
  smScreen = false;
  marketing = false;
  isLogged = false;
  lang = this.translater.currentLang;
  landingA = false;
  constructor(
    private authservice: AuthService,
    public userservice: UserService,
    private translater: TranslateService,
    public utilities: UtilitiesService,
    private dialog: MatDialog,
    private marketingService: MarketingService
  ) {}
  ngOnInit(): void {
    this.translater.onLangChange.subscribe((res) => {
      this.lang = res.lang;
    });
  }
  menuState: string = 'out';
  toggle() {
    console.log(this.menuState);

    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  close() {
    this.menuState = 'out';
  }
  ngOnChanges(changes: { closeNavbar: SimpleChange }): void {
    if (!changes.closeNavbar.isFirstChange()) {
      this.close();
    }
  }

  logout() {
    localStorage.removeItem('landing');
    this.authservice.logout();
  }

  changeLang() {
    event.preventDefault(); // Prevent the default anchor click behavior

    this.translater.get('app.langObject').subscribe((res) => {
      Swal.fire({
        title: res.chooseLang,
        icon: 'info',
        input: 'select',
        inputOptions: {
          fr: res.fr + ' : 🇫🇷',
          en: res.en + ' : 🇬🇧',
          cz: res.cz + ' : 🇨🇿',
        },
        showCloseButton: false,
        showCancelButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.lang = result.value;
          this.translater.use(result.value);
        }
      });
    });
  }
  openContact() {
    if (this.marketing) {
      this.openDiag();
    } else {
      this.dialog.open(ContactMeComponent);
    }
  }
  async openDiag() {
    await this.utilities.sendClickedButton(
      this.marketing ? 'A' : 'normal',
      'bookCall'
    );

    this.dialog.open(BookmeComponent, {
      width: window.innerWidth > 768 ? '40%' : '100%',
    });
  }
  openContactModal() {
    event.preventDefault(); // Prevent the default anchor click behavior

    this.marketingService.sendPixel('navbar', 'contact-dialog');
    this.dialog.open(ContactUsModalComponent, {
      width: 'auto',
      height: window.innerWidth < 768 ? '50%' : '98%',
    });
  }
}
