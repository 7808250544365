import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UtilitiesService } from '../services/utilities/utilities.service';
import { FlagsIconComponent } from '../flags-icon/flags-icon.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.css'],
  standalone: true,
  imports: [TranslateModule, FlagsIconComponent],
})
export class LanguageButtonComponent implements OnInit {
  constructor(
    private utils: UtilitiesService,
    private translater: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    translater.onLangChange.subscribe((res) => {
      if (this.lang !== res.lang) {
        this.lang = res.lang;
      }
    });
  }
  lang = this.translater.currentLang;

  changeLang() {
    const chosenLang = this.lang === 'fr' ? 'en' : 'fr';
    this.lang = chosenLang;

    this.utils.setLang(chosenLang);
    this.translater.use(chosenLang);
    // Replace the lang in the URL while preserving other route parameters
    // Construct the new URL with the updated lang
    // Get the current full URL segments
    const currentUrlSegments = this.router.url.split('/').filter(Boolean); // Remove empty segments

    // Replace the first segment (language) with the new lang
    const newUrlSegments = [chosenLang, ...currentUrlSegments.slice(1)];

    // Navigate to the updated URL
    this.router.navigate(newUrlSegments, {
      queryParams: this.activatedRoute.snapshot.queryParams, // Preserve query params
      replaceUrl: true, // Optionally replace in browser history
    });

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnInit(): void {
    // Initialize the language
  }
}
