import { Component, OnInit } from '@angular/core';
import { MarketingService } from '../services/marketing/marketing.service';
import { UtilitiesService } from '../services/utilities/utilities.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { AsyncPipe } from '@angular/common';
import { LanguageButtonComponent } from '../language-button/language-button.component';
import { MatAnchor } from '@angular/material/button';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [
    MatAnchor,
    RouterLink,
    LanguageButtonComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class FooterComponent implements OnInit {
  render = false;
  lang: string = this.translater.currentLang;
  constructor(
    public marketingService: MarketingService,
    public utils: UtilitiesService,
    public userservice: UserService,
    private translater: TranslateService
  ) {
    userservice.isLogged.subscribe((logged) => {
      this.render = !logged;
    });
    utils.appViewActive.subscribe((kidview) => {
      this.render = !kidview;
    });
  }
  ngOnInit(): void {
    this.translater.onLangChange.subscribe((res) => {
      this.lang = res.lang;
    });
  }
}
