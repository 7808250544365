<div>
  @if(!iswebview){
  <app-navbar [closeNavbar]="closeNavbar"></app-navbar>

  } @if ((isloading$)|async) {
  <div
    [style.background-color]="
      (utilities.holograming | async)
        ? 'black'
        : (utilities.appViewActive | async)
        ? 'var(--phonix-blue-white)'
        : 'white'
    "
    class="loader"
  >
    <mat-spinner
      class="spinner"
      [color]="(utilities.appViewActive | async) ? 'accent' : 'primary'"
    ></mat-spinner>
  </div>
  }
  <router-outlet> </router-outlet>
  @if(!iswebview){
  <app-footer></app-footer>
  }
</div>
